import { Box, Grid } from '@chakra-ui/layout';
import Heading from '@components/Heading';
import Container from '@components/LayoutStructure/Container';
import { StringToIconKeys } from '@components/RenderIcon';
import { HeadingElements } from '~/@types/heading';
import FeatureCardMini from './FeatureCardMini';
import { Color, getColor } from '@utils/getColor';

export interface Feature {
  title: string;
  description: string;
  featureAlignment: 'left' | 'right' | 'center';
  featureColor?: Color;
  icon: StringToIconKeys;
  iconColor?: Color;
  iconAlignment: 'left' | 'right' | 'center';
}

export interface FeaturesMiniProps {
  title?: string;
  headingElement?: HeadingElements;
  description?: string;
  titleAlignment?: 'center' | 'left' | 'right';
  titleColor?: Color;
  featureBackgroundColor?: Color;
  features: {
    features0: Feature;
    features1?: Feature;
    features2?: Feature;
    features3?: Feature;
    '@nodes': string[];
  };
}

const Features: React.FC<FeaturesMiniProps> = ({
  title,
  description,
  features,
  titleColor = { color: 'gray', range: '900' },
  featureBackgroundColor = { color: 'white', range: '900' },
  titleAlignment = 'center',
  headingElement = 'h2',
}) => {
  const featureNodes = features
    ? features['@nodes'].map((node) => features[node])
    : [];
  const color = getColor(titleColor.color, titleColor.range);
  const numberOfFeatures = featureNodes.length;
  return (
    <Container className="feature-mini-container">
      <Box mt="8">
        <Heading
          headingElement={headingElement}
          title={title || ''}
          withContainer={false}
          textAlign={titleAlignment}
          color={color}
        />
      </Box>

      <Box
        color={color}
        fontSize="xl"
        fontWeight="400"
        lineHeight="150%"
        textAlign={titleAlignment}
        mt="4"
        dangerouslySetInnerHTML={{ __html: description || '' }}
      />

      <Grid
        columnGap={{ base: '18px', lg: '45px' }}
        justifyContent="space-evenly"
        mt={10}
        mb="60px"
        rowGap={5}
        templateColumns={{
          base: 'repeat(1, minmax(0, 1fr))',
          sm: `repeat(${
            numberOfFeatures > 2 ? 2 : numberOfFeatures
          }, minmax(0, 1fr))`,
          lg: `repeat(${numberOfFeatures}, minmax(0, 1fr))`,
        }}
      >
        {featureNodes.map((feature) => {
          const {
            title,
            description,
            icon,
            iconColor,
            iconAlignment,
            featureColor,
            featureAlignment,
          } = feature;
          return (
            <FeatureCardMini
              key={title}
              title={title || ''}
              description={description}
              icon={icon}
              iconColor={getColor(iconColor.color, iconColor.range)}
              iconAlignment={iconAlignment}
              featureColor={getColor(featureColor.color, featureColor.range)}
              featureAlignment={featureAlignment}
              backgroundColor={getColor(
                featureBackgroundColor.color,
                featureBackgroundColor.range
              )}
            />
          );
        })}
      </Grid>
    </Container>
  );
};

export default Features;
