import { Icon } from '@chakra-ui/icon';
import { Box, Text } from '@chakra-ui/layout';
import { StringToIconKeys, STRING_TO_ICON_CLASS } from '@components/RenderIcon';

interface FeatureCardProps {
  icon: StringToIconKeys;
  title: string;
  description: string;
  iconColor: string;
  featureColor: string;
  iconAlignment: 'left' | 'right' | 'center';
  featureAlignment: 'left' | 'right' | 'center';
  backgroundColor: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  featureColor,
  iconColor,
  iconAlignment,
  featureAlignment,
  backgroundColor,
}) => {
  return (
    <Box
      width="100%"
      backgroundColor={backgroundColor}
      rounded="xl"
      padding={5}
    >
      <Box color={iconColor} textAlign={iconAlignment}>
        <Icon as={STRING_TO_ICON_CLASS[icon]} boxSize="67px" />
      </Box>
      <Text
        color={featureColor}
        fontSize="xl"
        fontWeight="700"
        lineHeight="120%"
        textAlign={featureAlignment}
        my={4}
      >
        {title}
      </Text>
      <Box
        color={featureColor}
        fontSize="sm"
        fontWeight="400"
        lineHeight="150%"
        textAlign={featureAlignment}
        mt="2"
        className="magnolia-text"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Box>
  );
};

export default FeatureCard;
